import { faBook, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import ReactTimeAgo from "react-time-ago";

export default function Anilist({ userId }) {
  const [updates, setUpdates] = useState({});
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState("...");
  useEffect(() => {
    update();
    let updateInterval = setInterval(update, 30e3 * 60);
    return () => {
      clearInterval(updateInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function update() {
    var url = "https://graphql.anilist.co";
    let query = `
      query($id: Int){
        Page{
          activities(userId:$id, type:MEDIA_LIST, sort:ID_DESC){
            ... on ListActivity {
              media{
                type
                status
                title {
                  userPreferred
                }
                episodes 
                chapters
                isAdult
                coverImage {
                  medium
                }
                siteUrl
              }
              user {
                name
              }
              type
              status
              progress
              createdAt
            }
          }
        }
      }
    `;
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query: query,
        variables: { id: userId },
      }),
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        setUser(res.data.Page.activities[0].user.name);
        setUpdates(res.data.Page.activities.slice(0,10));
        setLoading(false);
      });
  }
  if (!loading)
    return (
      <div className="tracker-box box">
        <div className="media-header">
          <a
            href={`https://anilist.co/user/${user}`}
            target="_blank"
            rel="noreferrer"
          >
            <p>{user}</p>
          </a>
          <div className="flex-grow" />
        </div>
        <hr />
        <ul className="media-tracks">
          {updates.map((update, i) => {
            if (!update.media.isAdult)
              return (
                <li className="media-track" key={i}>
                  <a
                    href={update.media.siteUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={update.media.coverImage.medium}
                      alt={update.media.title.userPreferred}
                      className="media-track-art"
                    />
                    <div className="media-track-date">
                      {update.media.type === "MANGA" ? (
                        <FontAwesomeIcon icon={faBook} />
                      ) : (
                        <FontAwesomeIcon icon={faVideo} />
                      )}{" "}
                      <ReactTimeAgo date={new Date(update.createdAt * 1000)} />
                    </div>
                    <div className="media-track-text">
                      <p>{update.media.title.userPreferred}</p>
                      {}
                      <p>{`${
                        update.status[0].toUpperCase() + update.status.slice(1)
                      } ${update.progress ? update.progress : ""}`}</p>
                      {/* <p>{track.album["#text"]}</p> */}
                      {/* <div className="flex-grow" /> */}
                    </div>
                  </a>
                </li>
              );
            else return null;
          })}
        </ul>
      </div>
    );
}
