import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProjectBox({
  name,
  description,
  live,
  screenshot,
  github,
}) {
  return (
    <div className="project-box box">
      <a
        href={live ? live : github}
        target="_blank"
        rel="noreferrer"
        className="project-screen"
        style={{
          backgroundImage: `url(${screenshot})`,
          backgroundPositionX: "center",
          backgroundSize: "cover",
        }}
      >
        <div />
      </a>
      <div className="project-right">
        <div className="project-details">
          <a href={live} target="_blank" rel="noreferrer">
            <p className="p-1">
              {name} {live ? <FontAwesomeIcon icon={faLink} /> : null}
            </p>
          </a>
          <hr />
          <p className="p-1">{description}</p>
        </div>
        <div className="flex-grow" />
        <hr className="w-full" />
        <div className="project-footer">
          {github ? (
            <a href={github} target="_blank" rel="noreferrer">
              <p>
                <FontAwesomeIcon
                  icon={faGithub}
                  className="social text-[#333] dark:text-[#fafafa]"
                />
                Github
              </p>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}
