import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLastfm,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  return (
    <span className="socials">
      <a
        href="mailto:isaiahmfuller@gmail.com"
        target="_blank"
        rel="noreferrer"
        className="social"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a
        href="https://www.linkedin.com/in/isaiahfuller/"
        target="_blank"
        rel="noreferrer"
        className="social"
      >
        <FontAwesomeIcon
          icon={faLinkedin}
          className="text-[#0a66c2] dark:text-[#e9e9ea]"
        />
      </a>
      <a
        href="https://github.com/isaiahfuller"
        target="_blank"
        rel="noreferrer"
        className="social"
      >
        <FontAwesomeIcon
          icon={faGithub}
          className="text-[#333] dark:text-[#fafafa]"
        />
      </a>
      <a
        href="https://twitter.com/zeyfuller"
        target="_blank"
        rel="noreferrer"
        className="social"
      >
        <FontAwesomeIcon color="#1da1f2" icon={faTwitter} />
      </a>
      <a
        href="https://last.fm/user/zeyfuller"
        target="_blank"
        rel="noreferrer"
        className="social"
      >
        <FontAwesomeIcon color="#d51007" icon={faLastfm} />
      </a>
    </span>
  );
}
