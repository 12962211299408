import Anilist from "./components/Anilist/index.jsx";
import Lastfm from "./components/Lastfm/index.jsx";
import Navbar from "./components/Navbar.jsx";
import ProjectBox from "./components/ProjectBox.jsx";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import me from "./img/me.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import Carousel from "./components/Carousel/index.jsx";

function App() {
  useEffect(() => {}, []);

  return (
    <div className="App">
      <div className="container">
        <div className="header">
          <div className="header-left">
            <h1 className="header-text">Hi, I'm Isaiah Fuller</h1>
            <ul className="header-list">
              <li>
                <p>Software Engineer</p>
              </li>
              <li>
                <p>Web Developer</p>
              </li>
              <li>
                <p>Computer Science Graduate</p>
              </li>
            </ul>
          </div>
          <div className="header-right">
            <img src={me} alt="me" className="headshot" />
          </div>
        </div>
        <div className="flex w-full py-3">
          <h1 className="header-text">Projects</h1>
          <div className="flex-grow" />
        </div>
      </div>
      <Carousel>
        <ProjectBox
          name="Newstube"
          live="https://isaiah.moe/newstube"
          github="https://github.com/isaiahfuller/newstube"
          description="A 24/7 YouTube player made using React and TailwindCSS."
          screenshot="https://user-images.githubusercontent.com/9921699/232338917-e9bff0b6-5c58-4a0e-9a0b-d3ee56e81582.png"
        />
        <ProjectBox
          name="GAMR"
          live="https://isaiah.moe/gamr"
          github="https://github.com/isaiahfuller/gamr"
          description="A project built using React, React Router, Chakra UI, Express and MongoDB to find new games to play."
          screenshot="https://media.discordapp.net/attachments/626872449858011157/1079537539620290720/UjJqnmw.png?width=705&height=524"
        />
        <ProjectBox
          name="gpt-songtitle-storyteller"
          live="https://isaiah.moe/storyteller"
          github="https://github.com/isaiahfuller/gpt-songtitle-storyteller"
          description="A project built using React, TailwindCSS, Express and GPT that turns song titles (not lyrics) into a story."
          screenshot="https://media.discordapp.net/attachments/974430787745239070/1136221145088139264/gHwrAwd.png?width=681&height=469"
        />
        <ProjectBox
          name="Boomer Shooter Launcher"
          github="https://github.com/isaiahfuller/BoomerShooterLauncher"
          description="A game launcher for retro FPS games (just Doom-based games for now) made with Python and Qt6."
          screenshot="https://user-images.githubusercontent.com/9921699/232340110-3b53d266-87fc-4c90-ac7e-ba10ef65571d.png"
        />
        <ProjectBox
          name="Clip Complex"
          github="https://github.com/isaiahfuller/clipcomplex"
          description="A project built using React, Supabase (for auth and db) and Chakra UI to find clips of Twitch streamers on YouTube."
          screenshot="https://user-images.githubusercontent.com/9921699/184048059-fa93a2fb-d542-4949-b36f-eb5caad6b309.png"
        />
      </Carousel>
      <div className="container">
        <div className="flex w-full py-3">
          <a href="https://drive.google.com/file/d/1t3phDrTyKRSSmJOUv5wTU6Z1CEXd2vzi/view?usp=share_link">
            <h1 className="header-text">
              About Me (Resume) <FontAwesomeIcon icon={faLink} />
            </h1>
          </a>
          <div className="flex-grow" />
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-around w-full">
          <div>
            <ul className="py-1">
              <li>Graduated from Eastern Michigan University in 2019</li>
              <ul className="list-inside list-disc px-1">
                <li>Major: Computer Science Applied</li>
                <li>Minor: Computer Engineering</li>
              </ul>
              <li>Part of Formation fellowship</li>
              <ul className="list-inside list-disc px-1">
                <li>
                  Selected for competitive fellowship for coaching from
                  engineers
                </li>
                <li>
                  Completed intensive training to master computer science
                  fundamentals
                </li>
              </ul>
            </ul>
          </div>
          <hr className="border m-auto lg:hidden w-full" />
          <div className="flex flex-col text-center">
            <p>
              This is just a small WIP site to show my projects and introduce
              myself.
            </p>
            <p>I built this site with React, TailwindCSS, and Framer Motion.</p>
            <Navbar />
          </div>
        </div>
        <div className="flex w-full py-3">
          <h1 className="header-text">Media trackers</h1>
          <div className="flex-grow" />
        </div>
        <div className="trackers">
          <Lastfm username="zeyfuller" />
          <Anilist userId={57505} />
        </div>
      </div>
    </div>
  );
}

export default App;
