/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import { useAnimate, motion } from "framer-motion";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function Carousel({ children }) {
  const { width } = useWindowDimensions();
  const fullConfig = resolveConfig(tailwindConfig);
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [visibleChildren, setVisibleChildren] = useState(1);
  const [transform, setTransform] = useState(0);
  const [boxWidth, setBoxWidth] = useState("500px");
  const [scope, animate] = useAnimate();

  useEffect(() => {
    let size = "default";
    let newVisibleChildren = visibleChildren;
    let newBoxWidth = width;
    const screens = Object.entries(fullConfig.theme.screens);
    for (let screen of screens) {
      if (width >= parseInt(screen[1].replace(/px/g, ""))) {
        size = screen[0];
        if (size === "lg") {
          newVisibleChildren = 2;
          newBoxWidth = Math.floor(width / 2);
        }
        if (size === "2xl") {
          newVisibleChildren = 3;
          newBoxWidth = Math.floor(width / 3);
        }
      }
    }
    setVisibleChildren(newVisibleChildren);
    setBoxWidth(newBoxWidth + "px");
    handleResize();
    setLoading(false);
  }, [width, index]);

  function paginate(direction) {
    let newDir = index + direction;
    if (newDir < 0) newDir = 0;
    if (newDir > children.length - 1) newDir = children.length - 1;
    setIndex(newDir);
    handleResize(newDir);
  }

  function handleResize(i = index) {
    let newTransform = transform;
    newTransform = -(parseInt(boxWidth) * i);
    if (newTransform > 0) newTransform = 0;
    if (scope.current) {
      animate(scope.current, { x: newTransform + "px" }, { duration: 0.3 });
    }
    setTransform(newTransform);
  }

  if (loading) return <p>Loading...</p>;
  return (
    <div className="carousel-container">
      <motion.div
        className="carousel"
        ref={scope}
        drag="x"
        dragMomentum={false}
        onDragEnd={(event, info) =>
          paginate(-Math.round(info.offset.x / parseInt(boxWidth)))
        }
      >
        <div key={index} className="projects">
          {children.map((child, i) => {
            return (
              <div
                key={i}
                style={{ width: boxWidth }}
                className="carousel-item"
              >
                {child}
              </div>
            );
          })}
        </div>
      </motion.div>
      <div className="carousel-nav">
        <button
          className="carousel-button"
          onClick={(e) => paginate(-1)}
          disabled={!index}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <div>
          {index + 1}/{children.length}
        </div>
        <button
          className="carousel-button"
          onClick={(e) => paginate(1)}
          disabled={index === children.length - 1}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}
