// d9e2a3252c9489db727b244ede92f356
import React from "react";
import { faLastfm } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

export default function Lastfm({ username }) {
  const [tracks, setTracks] = useState({});
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    updateLfm();
    let updateInterval = setInterval(updateLfm, 30e3);
    return () => {
      clearInterval(updateInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function updateLfm() {
    fetch(
      `https://ws.audioscrobbler.com/2.0/?method=user.getrecenttracks&user=${username}&api_key=d9e2a3252c9489db727b244ede92f356&format=json`
    )
      .then((res) => res.json())
      .then((data) => {
        setTracks(data["recenttracks"]["track"].slice(0,10));
        setUser(data["recenttracks"]["@attr"]);
        setLoading(false);
      });
  }
  if (loading) return null;
  else
    return (
      <div className="tracker-box box">
        <div className="media-header">
          <a
            href={`https://last.fm/user/${user.user}`}
            target="_blank"
            rel="noreferrer"
          >
          <p>
            <FontAwesomeIcon icon={faLastfm} color="#d51007" /> {user.user}</p>
          </a>
          <div className="flex-grow" />
        </div>
        <hr />
        <ul className="media-tracks">
          {tracks.map((track, i) => {
            return (
              <li className="media-track" key={i}>
                <a href={track.url} target="_blank" rel="noreferrer">
                  <img
                    src={track.image[1]["#text"]}
                    alt={track.name}
                    className="media-track-art"
                  />
                  <div className="media-track-date">
                    {track.date ? (
                      <>
                        <FontAwesomeIcon icon={faMusic} />{" "}
                        <ReactTimeAgo date={new Date(track.date.uts * 1000)} />
                      </>
                    ) : (
                      <span className="flex">
                        <img
                          className="px-1"
                          src="https://github.com/ardacetinkaya/vscode-lastfm-listening-now/raw/master/media/now_playing.gif"
                          alt=""
                        />
                        <p>Now playing</p>
                        <div className="flex-grow" />
                      </span>
                    )}
                  </div>
                  <div className="media-track-text">
                    <p>{track.name}</p>
                    <p>{track.artist["#text"]}</p>
                    <p>{track.album["#text"]}</p>
                    {/* <div className="flex-grow" /> */}
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
}
