/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    extend: {backgroundImage: {
      'light': "url('./img/layered-waves-haikei.svg')",
      'dark': "url('./img/polygon-scatter-haikei.svg')"
    }},
  },
  plugins: [],
}
